import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { Columns, Container } from "react-bulma-components";
import styled from "styled-components";

import { ContainerMobile, Layout, Title } from "../components/index";

const ColumnImage = styled(Columns.Column)`
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;

    @media screen and (min-width: 769px) {
        max-width: 368px;
    }
`;

const ColumnText = styled(Columns.Column)`
    padding-top: 0;
    padding-bottom: 0;
`;

const PText = styled.p`
    padding: ${props => props.theme.space.vertical};
    background-color: ${props => props.theme.color.shadedBackground};
`;

const About = (props) => {
    return (
        <Layout {...props}>
            <Title>About</Title>

            <Container>
                <ContainerMobile>
                    <Columns my="0">
                        <ColumnImage size="one-third">
                            <StaticImage src="../../images/about/about.jpg" alt="Too Tall Tyler on a rocky beach outcrop" />
                        </ColumnImage>

                        <ColumnText renderAs="article">
                            <PText>
                                Doug {"\"Too Tall\""} Tyler started playing guitar in the summer of 1969 at age 14.
                                His influences include John Lennon, Jimi Hendrix, and Johnny Winter.
                                After several high school and college bands, Doug toured the U.S. in 1978 with Expressway, who opened for Steppenwolf.
                                <br /><br />
                                In 1979, he formed New Toys, {"Buffalo's"} premier original new wave rock band of the early {"80's"}.
                                They toured regionally, put out a single and an album titled <i>Say It</i>,
                                and opened for Pat Benatar, Squeeze, and The Romantics.
                                After moving to NYC, they played various clubs, including {"CBGB's"}.
                                <br /><br />
                                Returning to Buffalo in 1984, Doug formed The Layers, who opened for Johnny Winter, and played locally until 1988.
                                Doug continued to play in the Buffalo area in Phil n the Blanks (1992 - 1996),
                                Rockasaurus (1997 - 2005), Flashback (2006 - 2009), and Mr. MAX (2015 - 2017).
                                <br /><br />
                                Too Tall Tyler and The Tattletales was formed in July 2018, as Doug wanted to try a power trio format again.
                                The band plays a mix of danceable Blues Rock and Classic Rock.
                                <br /><br />
                                Doug also has an offshoot band called The Double D Band, with his wife Deborah Anne on rhythm guitar.
                                <br /><br />
                                For bookings, visit the <Link to="/contact/">contact page.</Link>
                            </PText>
                        </ColumnText>
                    </Columns>
                </ContainerMobile>
            </Container>
        </Layout>
    );
};

export default About;